.Desc {
    text-align: left;
    margin: auto;
}

.Desc-header {
    padding-left: 5px;
    font-size: 9px;
}

.Desc-main {
    padding-left: 5px;
}

.Desc-main p {
    padding-left: 5px;
}

.Desc-main ul {
    list-style-type: none;
    padding-left: 5px;
}